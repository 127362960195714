import { createRouter, createWebHistory } from 'vue-router'
import ContestView from '../views/ContestView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ContestView
  },
  {
    path: '/thamgiacuocthi',
    name: 'thamgiacuocthi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ThamGiaView.vue')
  },
  {
    path: '/thongbaoketqua',
    name: 'thongbaoketqua',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ThongBaoKetQuaView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
