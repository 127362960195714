<!-- Footer.vue -->
<template>
  <footer> &nbsp; <div class="footer">
      <div class="common-info bg1" style="
    padding: 0px 10px;
">
        <div class="">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="col-md-4 col-sm-12" style="
    margin-left: -20px;
">
                <a href="https://baodaklak.vn/"><img
                    src="https://baodaklak.vn/file/fb9e3a03798789de0179a1704dea238e/f13eaeaa63442578016345c47b8a01f5/072021/Logo11_20210713165855.png"
                    style="
    width: 100%;
"></a>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">

              <p>Cơ quan chủ quản: Tỉnh ủy Đắk Lắk</p>
              <p>Giấy phép xuất bản số 31/GP-BTTTT ngày 21/01/2022 của Bộ TT-TT
              </p>
              <p>Tổng Biên tập: Đinh Xuân Toản</p>
              <p>Phó Tổng Biên tập: Lê Quang Ánh - Lê Minh Thược - Đàm Thị Thuần</p>
            </div>
            <div class="col-md-6 col-sm-12">
              <p style="">Tòa soạn: 23 Lê Duẩn, TP. Buôn Ma Thuột, tỉnh Đắk Lắk</p>
              <p style="">Điện thoại: (0262) 3852383 - 3810414 - Fax: (0262) 3810451 - Email:
                toasoan.baodaklak@gmail.com</p>
              <p style="">Ghi rõ nguồn “Báo Đắk Lắk Điện tử” khi phát hành lại thông tin từ website này</p>
              <a href="https://baodaklak.vn" class=" toggle_textlink">
                <p style="    color: white;">Các trang ngoài sẽ mở ra tại cửa sổ mới. Báo Đắk Lắk không chịu
                  trách nhiệm nội dung các trang này</p>
              </a>
            </div>

            <!-- <div class="col-md-12">
              <div class="menu-footer">
                <a href="/">Trang chủ</a>
                &nbsp;/&nbsp;
                <a href="/?ads_fb=true">Liên hệ quảng cáo</a>&nbsp;/&nbsp;
                <a href="#">Báo giá quảng cáo</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="scrolltop-button" style="display: block;"><img src="https://baodaklak.vn/common/v1/images/top.png"></div>
    <!-- 
<script src="/common/v1/js/lazyload.min.js"></script>
<script src="/common/v1/js/swiper.js?v=1.1"></script>
<script src="/common/v1/js/main.js?v=1.7"></script>
<script src="/common/v1/js/menu-resize.js">.</script>
<script src="/common/v1/js/bnad.js"></script>
<script>
var urlPrint = "/in/";
$( "<a title=\"In bài này\" href=\"javascript: void(0);\" onclick=\"window.open(urlPrint);\" class=\"print\"><i class=\"fa fa-print\" aria-hidden=\"true\"></i></a>" ).insertAfter( ".share_email_article" );

//thong ke

var article_title = $("#article_title").html();
if(article_title == "") article_title  = $("#cate_title").html();
var stat_img_src = "/sc_service/api/statistic/statcollector?"+
//var stat_img_src = "/sc_service/api/statistic/statCollectorToMemcache?"+
"scUnitMapId=" +$("#site_id").html()+
"&cateId="+$("#cate_id").html()+
"&objectId="+$("#article_id").html()+
"&type=1"+
"&pageTitle="+article_title
+"&referer="+window.location.href;
$("body").append("<img src=\""+ stat_img_src + "\" width=\"0\" height=\"0\" style=\"height: 0;display: none;\"/>");

</script>
<script language="javascript">
 
		</script>
<script src="https://sp.zalo.me/plugins/sdk.js"></script>
<script>
 $(".toggle_textlink ").click(function(){
    $(".textlink_embed").toggle();
  return false;
  });
</script> -->
  </footer>
</template>
  
<script>
export default {
  // Component logic goes here
};
</script>
  
<style scoped>
/* Component styles go here */
@import '@/assets/style/main.css';
@import '@/assets/style/bootstrap.min.css';
.cate_title_1>a {
  color: red;
  border-bottom: 1px solid #fa0432;
}

.cate_title_1 {
  height: 30px;
}

#content table.center {
  margin: 0px auto;
}</style>
  