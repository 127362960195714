<!-- Header.vue -->
<template>
  <div class="header">
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <!--  <a href="/"><img class="logo" src="https://baodaklak.vn/file/fb9e3a03798789de0179a1704dea238e/122022/d3546f1efbc823967ad9_20221231155757.jpg" alt="Báo Đắk Lắk điện tử"/></a>    -->
        <a href="https://baodaklak.vn/"><img class="logo" src="@/assets/images/logo.jpg" alt="Báo Đắk Lắk điện tử"></a>
      </div>
      <div class="col-md-8 col-xs-12 header_right">
        <div style="display: block;" class="fb9e3a03798789de017a2c5364971d9d ad_banner" name="vsad_border">
          <div class="carousel1 slide carousel-fade" data-ride="carousel" data-interval="5000">
            <div class="carousel-inner">
              <div class="item active"><a href="/" target="_blank"><img src="@/assets/images/banner-2.jpg?width=&amp;height=-&amp;type=resize"></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
  
<script>
export default {
  // Component logic goes here
};
</script>
  
<style scoped>/* Component styles go here */
.header {
  padding-top: 10px;
  padding-bottom: 0px;
}</style>
  