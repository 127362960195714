<template>
    <div>
        <main id="bcv-wrp">

            <section class="">
                <!-- <div class="masthead lr-ct uk-container"> -->
                <div class="masthead lr-ct ">
                    <div class="topic__cover">
                        <div class="topic__intro"></div>
                    </div>

                </div>
            </section>
            <div class="uk-container">
                <section class="bcv-row">
                    <div class="uk-grid-medium uk-grid-divider uk-grid" uk-grid="">
                        <div class="uk-width-auto uk-first-column uk-visible@m">
                            <aside class="bcv-left-side">
                                <div class="uk-sticky-placeholder" style="height: 0px; width: 0px; margin: 0px;"></div>
                                <div class="main__menu uk-sticky" uk-sticky="offset: 30; top: true;" style="">
                                    <ul class="uk-nav" uk-nav="">
                                        <li><a class="" href="/#doi-tuong-noi-dung" uk-switcher-item="0">Đối tượng và nội
                                                dung</a>
                                        </li>
                                        <li><a href="/#hinh-thuc-thoi-gian" uk-switcher-item="1" class="">Hình thức và thời
                                                gian thi</a>
                                        </li>
                                        <li><a href="/#co-cau-giai-thuong" uk-switcher-item="2" class="">Cơ cấu giải
                                                thưởng</a></li>
                                        <li><a href="/#tai-lieu-cuoc-thi" uk-switcher-item="2" class="">Tài liệu cuộc thi</a></li>
                                        <li><a href="/#quy-dinh-chung" uk-switcher-item="2" class="">Quy định chung</a></li>
                                        <li><a href="thamgiacuocthi" uk-switcher-item="2" class="">Tham gia cuộc thi</a>
                                        </li>
                                        <li><a href="thongbaoketqua" uk-switcher-item="2" class="">Thông báo kết quả</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="uk-sticky-placeholder" style="height: 240px; margin: 0px;" hidden=""></div>

                            </aside>
                        </div>
                        <div class="uk-width-expand">
                            <div class="uk-container">
                                <div id="doi-tuong-noi-dung">
                                    <h2><strong style="font-size: large">I- ĐỐI TƯỢNG, NỘI DUNG</strong></h2>
                                    <p style="text-align: justify"><strong>1. Đối tượng dự thi:</strong></p>
                                    <p style="text-align: justify">Là công dân Việt Nam sinh sống trong và ngoài tỉnh Đắk
                                        Lắk (sau đây gọi tắt là thí sinh).</p>
                                    <p style="text-align: justify">Thành viên Ban Tổ chức, Ban Giám khảo, Tổ giúp việc Cuộc
                                        thi không được tham gia thi.</p>
                                    <p style="text-align: justify"><strong>2. Nội dung</strong> <strong>thi</strong></p>
                                    <p style="text-align: justify"><strong>2.1. Thi trắc nghiệm: </strong>Thi tìm hiểu kiến
                                        thức về lịch sử tỉnh Đắk Lắk qua 120 năm hình thành, xây dựng và
                                        phát triển; những thành tựu nổi bật, nét văn hóa tiêu biểu, đặc trưng của tỉnh…</p>
                                    <p style="text-align: justify"><strong>2.2. Thi viết: </strong>Những thí sinh đạt giải ở
                                        vòng thi tuần sẽ tiếp tục dự thi vòng chung khảo; viết bài tự luận về một trong các
                                        chủ đề được Ban Tổ chức Cuộc thi đưa ra. Nội dung chủ đề sẽ được Ban Tổ chức Cuộc
                                        thi công bố, đăng tải trên Báo điện tử Đắk Lắk vào ngày 09/5/2024.</p>
                                    <p style="text-align: justify"><strong>3. Tư liệu tham khảo Cuộc thi</strong>: Được đăng
                                        tải trên báo Đắk Lắk điện tử</p>
                                </div>



                                <div id="hinh-thuc-thoi-gian">
                                    <h2><strong  style="font-size: large">II- HÌNH THỨC VÀ THỜI GIAN THI</strong></h2>
                                    <p> </p>
                                    <p style="text-align: justify"><strong>1.&nbsp;&nbsp;&nbsp;&nbsp; Hình thức thi:
                                        </strong></p>
                                    <p> </p>
                                    <p style="text-align: justify">Kết hợp đồng thời giữa 02 hình thức thi trắc nghiệm và
                                        thi viết. </p>
                                    <p> </p>
                                    <p style="text-align: justify"><strong>1.1. Thi trắc nghiệm (Vòng thi tuần)
                                            </strong></p>
                                    <p> </p>
                                    <p style="text-align: justify">1.1.1. Thời gian:</p>
                                    <p> </p>
                                    <p style="text-align: justify">Cuộc thi được tổ chức trong <strong>6 tuần</strong> thi.
                                        Bắt đầu từ ngày <strong>07/03/2024</strong>, kết thúc vào ngày
                                        <strong>02/5/2024</strong>. </p>
                                    <p> </p>
                                    <p style="text-align: justify">1.1.2. Cách thức dự thi: </p>
                                    <p> </p>
                                    <p style="text-align: justify">Thí sinh có thể lựa chọn tham gia Cuộc thi qua
                                        <strong>hai hình thức: </strong></p>
                                    <p style="text-align: justify"><strong>- Cách thức thứ nhất :</strong> Thi trắc nghiệm
                                        online qua Báo Đắk Lắk điện tử tại địa chỉ&nbsp;<a target="_blank"
                                            rel="noopener noreferrer nofollow"
                                            href="https://timhieu.baodaklak.vn/thamgiacuocthi">https://timhieu.baodaklak.vn/thamgiacuocthi</a>.&nbsp;
                                    </p>
                                    <p style="text-align: justify">- <strong>Cách thức thứ hai:</strong> Thí sinh Quét mã QR
                                        link Cuộc thi được đăng và chuyển tải trên môi trường Internet (qua mạng xã hội
                                        Facebook, Zalo). </p>
                                    <p style="text-align: justify">- Sau khi lựa chọn một trong hai cách trên, thí sinh
                                        điền các thông tin họ và tên; đơn vị; số điện thoại (bắt buộc). Ban Tổ chức
                                        không chịu trách nhiệm nếu thí sinh đoạt giải nhưng không đúng thông tin để liên hệ.
                                    </p>
                                    <p style="text-align: justify">- Thí sinh trả lời 10 câu hỏi trắc nghiệm (chọn 01 trong
                                        03 đáp án). Sau khi trả 10 câu hỏi trắc nghiệm, thí sinh dự đoán số người tham vòng
                                        thi (bắt buộc).</p>
                                    <p style="text-align: justify">- Sau đó thí sinh nhấn nút “Gửi bài”, hệ thống sẽ tự động
                                        lưu kết quả.</p>
                                    <p style="text-align: justify">- Mỗi câu trả lời đúng được 10 điểm. Điểm tối đa 100
                                        điểm. </p>
                                    <p> </p>
                                    <p style="text-align: justify"><strong>1.2. Thi viết (Vòng chung khảo)</strong>
                                    </p>
                                    <p> </p>
                                    <p style="text-align: justify">- Thí sinh đạt giải ở 06 vòng thi tuần được tham gia vòng
                                        chung khảo. </p>
                                    <p style="text-align: justify">- Mỗi thí sinh làm <strong>01 bài thi tự luận</strong>
                                        theo <strong>01 trong 03 chủ đề</strong> được Ban Tổ chức Cuộc thi đưa ra. Sẽ công
                                        bố trên Báo Đắk Lắk sau khi kết thúc vòng thi tuần.</p>
                                    <p style="text-align: justify">- Nội dung các bài thi sử dụng chữ viết bằng tiếng Việt
                                        (đánh máy, hoặc viết tay); khổ giấy A4, &nbsp;dung lượng bài thi tối thiểu là 05
                                        trang A4, tối đa 20 trang A4 (không tính phần hình ảnh minh họa); ưu tiên trình bày
                                        rõ ràng, bố cục chặt chẽ, khoa học, có ý tưởng mới, sáng tạo, liên hệ thực tiễn sâu
                                        sát, sinh động…</p>
                                    <p style="text-align: justify">- Điểm thi viết tối đa là 100 điểm.</p>
                                    <p> </p>
                                    <p style="text-align: justify"><strong>2. Thời gian thi</strong></p>
                                    <p> </p>
                                    <p style="text-align: justify">- Thi trắc nghiệm: Diễn ra trong 6 tuần</p>
                                    <p style="text-align: justify">+ Tuần 1: Từ ngày 07/03/2024 đến hết ngày 13/3/2024</p>
                                    <p style="text-align: justify">+ Tuần 2: Từ ngày 17/03/2024 đến hết ngày 23/3/2024</p>
                                    <p style="text-align: justify">+ Tuần 3: Từ ngày 27/03/2024 đến hết ngày 02/4/2024</p>
                                    <p style="text-align: justify">+ Tuần 4: Từ ngày 06/4/2024 đến hết ngày 12/4/2024</p>
                                    <p style="text-align: justify">+ Tuần 5: Từ ngày 16/4/2024 đến hết ngày 22/4/2024</p>
                                    <p style="text-align: justify">+ Tuần 6: Từ ngày 26/4/2024 đến hết ngày 02/5/2024</p>
                                    <p> </p>
                                    <p style="text-align: justify">- Thi viết (vòng chung khảo):&nbsp; Diễn ra từ ngày
                                        <strong>10/5/2024</strong>, đến ngày <strong>30/6/2024. </strong>Thí sinh nộp bài về
                                        Ban Tổ chức Cuộc thi (qua Ban Tuyên giáo Tỉnh ủy) theo 2 hình thức: Nộp trực tiếp về
                                        cơ quan Ban Tuyên giáo Tỉnh ủy (thời gian nhận bài thi trong giờ hành chính, kết
                                        thúc thời gian nhận bài trước 17h00’, ngày 30/6/2024. Đối với hồ sơ gửi qua đường
                                        bưu điện, ngoài phong bì ghi rõ: Bài tham dự Cuộc thi tìm hiểu “Lịch sử 120 năm hình
                                        thành, phát triển tỉnh Đắk Lắk”. Ban Tổ chức giải không chịu trách nhiệm nếu hồ sơ
                                        tham dự giải bị thất lạc hoặc hư hỏng do lỗi của bưu điện. Ban tổ chức Cuộc thi căn
                                        cứ theo dấu bưu điện để xem xét tính hợp lệ của bài thi.</p>
                                    <p> </p>
                                    <p style="text-align: justify">Hình thức bài thi được trình bày đẹp, khoa học; thể hiện
                                        đầy đủ thông tin của thí sinh (họ tên; địa chỉ/đơn vị; số điện thoại).</p>
                                    <p> </p>
                                    <p style="text-align: justify">- Tổng kết, trao giải thưởng Cuộc thi: Tháng 8/2024.</p>
                                </div>



                                <div id="co-cau-giai-thuong">
                                    <h2><strong  style="font-size: large">III- CƠ CẤU GIẢI THƯỞNG</strong></h2>
                                    <p style="text-align: justify"><strong>1. Giải thưởng tuần: </strong>Gồm 16 giải
                                        thưởng/1 vòng thi tuần; Giá trị giải thưởng tuần, cụ thể như sau:</p>
                                    <p style="text-align: justify">01 giải nhất: 2.000.000đ/giải</p>
                                    <p style="text-align: justify">02 giải nhì: 1.500.000đ/giải</p>
                                    <p style="text-align: justify">03 giải ba: 1.000.000đ/giải</p>
                                    <p style="text-align: justify">10 giải Khuyến khích: 500.000đ/giải</p>
                                    <p style="text-align: justify">Ban Giám khảo Cuộc thi căn cứ vào các yếu tố: đúng đáp
                                        án, thời gian dự thi; thời gian hoàn thành bài thi, số dự đoán số người tham gia (dự
                                        đoán đúng, hoặc dự đoán gần với số người dự thi) để xét giải thưởng vòng thi tuần.
                                    </p>
                                    <p style="text-align: justify">Kết quả của từng tuần thi sẽ được Ban tổ chức Cuộc thi
                                        đăng tải trên Báo Đắk Lắk điện tử.</p>
                                    <p style="text-align: justify"><strong>2. Giải thưởng vòng chung khảo</strong>: Dự kiến
                                        11 giải; Giá trị giải thưởng cụ thể như sau:</p>
                                    <p style="text-align: justify">01 giải nhất: 5.000.000đ/giải</p>
                                    <p style="text-align: justify">02 giải nhì: 3.000.000đ/giải</p>
                                    <p style="text-align: justify">03 giải ba: 2.000.000đ/giải</p>
                                    <p style="text-align: justify">05 giải Khuyến khích: 1.000.000đ/giải</p>
                                    <p style="text-align: justify">Bài viết dự thi vòng chung khảo là bài viết mới, chưa
                                        đăng tải trên báo chí, truyền thông, chưa tham gia và đạt giải ở những Cuộc thi
                                        khác. Ban Giám khảo căn cứ kết quả bài thi để xét giải thưởng theo thứ tự từ trên
                                        xuống dưới.</p>
                                    <p style="text-align: justify"><strong>3. Điều khoản chung về cơ cấu, giá trị Giải
                                            thưởng</strong></p>
                                    <p style="text-align: justify">Ngoài những giải thưởng, giá trị giải thưởng đã được công
                                        bố, tùy thuộc vào tình hình thực tiễn triển khai thực hiện, Ban Tổ chức Cuộc thi xem
                                        xét, đề xuất những nội dung khen thưởng bổ sung nhằm kịp thời động viên những tập
                                        thể, cá nhân xuất sắc trong triển khai thực hiện và tham gia Cuộc thi theo một trong
                                        những hình thức sau:</p>
                                    <p style="text-align: justify">- Báo cáo, đề xuất Ban Chỉ đạo các hoạt động kỷ niệm 120
                                        năm Ngày thành lập tỉnh Đắk Lắk xem xét tặng Bằng khen.</p>
                                    <p style="text-align: justify">- Vận động, xã hội hóa kinh phí khen thưởng (nếu có)</p>
                                </div>
                                <div id="tai-lieu-cuoc-thi">
                                    <h2><strong  style="font-size: large">IV- TÀI LIỆU CUỘC THI</strong></h2>
                                    <p style="text-align: justify"><strong>Tài liệu tuần 6: </strong> <a style="display: inline" href="https://baodaklak.vn/file/fb9e3a03798789de0179a1704dea238e/upload/xdata/202404/20240424103216tu_lieu_phuc_vu_cuoc_thi_tuan_6_24.4.pdf">Tải xuống</a></p>
                                </div>
                                <div id="quy-dinh-chung">
                                    <h2><strong  style="font-size: large;">V- QUY ĐỊNH CHUNG</strong></h2>
                                    <p style="text-align: justify">- Ban Tổ chức Cuộc thi không hạn chế số lần thi của thí
                                        sinh. Thí sinh có thể tham gia thi vào bất cứ thời gian nào trong khung giờ mà Ban
                                        Tổ chức thông báo. Trong quá trình thi, Ban Tổ chức không chịu trách nhiệm đối với
                                        lỗi kết nối do đường truyền mạng hoặc lỗi do thiết bị tham gia dự thi; không giải
                                        quyết ý kiến phúc khảo về kết quả thi.</p>
                                    <p style="text-align: justify">- Người dự thi nếu vi phạm Thể lệ thi hoặc thực hiện
                                        những hành động thiếu trung thực, gây ảnh hưởng đến kết quả Cuộc thi, sẽ bị Ban Tổ
                                        chức hủy bỏ kết quả, loại khỏi Cuộc thi hoặc thu hồi giải thưởng mà không cần thông
                                        báo trước.</p>
                                    <p style="text-align: justify">- Sản phẩm bài thi thuộc về Ban Tổ chức Cuộc thi; những
                                        bài đạt giải sẽ được Ban Tổ chức Cuộc thi sử dụng đăng tải trên báo, trang tin điện
                                        tử, truyền thanh cơ sở để phục vụ công tác tuyên truyền. Thí sinh chịu trách nhiệm
                                        về tư liệu bài viết của mình.</p>
                                    <p style="text-align: justify">- Ban Tổ chức giữ quyền quyết định cao nhất về giải
                                        thưởng; chỉ Ban Tổ chức mới có quyền trao giải thưởng và hủy giải thưởng.</p>
                                    <p style="text-align: justify">- Trong quá trình tổ chức cuộc thi (các vòng thi) nếu
                                        xuất hiện các vấn đề khác cần điều chỉnh, sửa đổi cho phù hợp, Ban Tổ chức sẽ hội ý,
                                        quyết định và thông báo đến thí sinh dự thi. Quyết định của Ban Tổ chức Cuộc thi là
                                        quyết định cuối cùng.</p>
                                    <p style="text-align: center">---*---</p>
                                </div>


                                <div class="bottom_mobile_bar_nav">
                                    <ul class="uk-nav" uk-nav="">
                                        <li><a class="bottom_mobile_bar" href="/#doi-tuong-noi-dung" uk-switcher-item="0">Đối tượng và nội
                                                dung</a>
                                        </li>
                                        <li><a class="bottom_mobile_bar" href="/#hinh-thuc-thoi-gian" uk-switcher-item="1">Hình thức và thời
                                                gian thi</a>
                                        </li>
                                        <li><a class="bottom_mobile_bar" href="/#co-cau-giai-thuong" uk-switcher-item="2">Cơ cấu giải
                                                thưởng</a></li>
                                        <li><a class="bottom_mobile_bar" href="/#tai-lieu-cuoc-thi" uk-switcher-item="2">Tài liệu cuộc thi</a></li>
                                        <li><a class="bottom_mobile_bar" href="/#quy-dinh-chung" uk-switcher-item="2">Quy định chung</a></li>
                                        <li><a class="bottom_mobile_bar" href="thamgiacuocthi" uk-switcher-item="2">Tham gia cuộc thi</a>
                                        </li>
                                        <li><a class="bottom_mobile_bar" href="thongbaoketqua" uk-switcher-item="2">Thông báo kết quả</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


            </div>




        </main>
    </div>
</template>

<script script>
import UIkit from 'uikit';
export default {
    name: "ContestView",
    mounted() {
        // Kích hoạt UIkit
        UIkit.sticky('.uk-sticky');
    }
}

</script>

<style scoped>
@import '@/assets/style/style.css';
@import 'uikit/dist/css/uikit.min.css';


.btn-xl {
    padding: 1.25rem 3.25rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    border: none;
    border-radius: 10rem;
    /* background-color: #fece26;  fefbad  #fff100*/
    background-color: #dd4848;
    line-height: 25px;
}

.masthead {
    position: relative;
    background: url(@/assets/images/banner-ct.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.masthead .btn-xl {
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 12;
    color: #fff;
    border: none;
    cursor: pointer;
}

.masthead a.btn-xl:link {
    color: #fff;
}

/* #db2121*/
.text-header::before {
    content: "";
    padding-left: 5px;
    border-left: solid 3px var(--main-red-color);
}

.text-header {
    font-size: 1rem;
    text-transform: uppercase;
}

/* .lr-ct a.uk-button{color:#db2121;}#faf67d */
.lr-ct a.uk-button:hover {
    background-color: #f31b1b;
}

.uk-tab>.uk-active>a {
    color: #333;
    border-bottom: 2px solid #db2121;
    font-weight: 600;
}

.uk-tab>*>a {
    font-size: 1rem;
}

/* .bcv-mid-details article p{text-indent: 2em;} */


.topic__cover {
    min-height: 500px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -10px 0 30px;
    border-radius: 0 0 5px 5px;
}

.topic__title {
    position: absolute;
    top: 5%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3rem;
    color: #fece26;
    text-align: center;
    max-width: 900px;
}

.topic__intro {
    position: absolute;
    top: 36%;
    max-width: 900px;
    text-align: center;
    color: #dd4848;
    font-size: 2rem;
    margin-top: 2.5rem;
    font-weight: 600;
}

#tai-lieu-tham-khao ul>li>a {
    color: #000;
    display: contents;
}

@media only screen and (max-width: 920px) {
    .masthead {
        background-color: #db2121;
    }

    .masthead .btn-xl {
        top: 70%;
    }

    .topic__title {
        font-size: 2.5rem;
        margin: 0 3rem;
    }

    .topic__intro {
        position: absolute;
        top: 25%;
        font-size: 1rem;
        margin: 0 3rem;
    }

    .topic__cover {
        margin: unset;
        min-height: 350px;
    }


}

@media only screen and (max-width: 768px) {
    .topic__title {
        font-size: 2.3rem;
        margin: 0 3rem;
    }

    .masthead .btn-xl {
        top: 75%;
    }
}

@media only screen and (max-width: 480px) {
    .btn-xl {
        padding: 0.2rem 0.5rem;
        font-size: .7rem;
        line-height: 22px;
    }

    .topic__title {
        font-size: 1.42rem;
        margin: 0 2rem 0 2rem;
        display: inline-block;
    }

    .topic__title img {
        margin: auto;
    }

    .topic__intro {
        position: absolute;
        top: 35%;
        font-size: .9rem;
        margin: 0 3rem;
    }

    /* .topic__cover{margin: unset;min-height: 380px;} */
    .topic__cover {
        margin: unset;
        min-height: 180px;
    }

    .masthead .btn-xl {
        top: 70%;
    }

    .masthead {
        position: relative;
        background: url(@/assets/images/banner-ct.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

/*--- menu left ----*/
.bcv-left-side {
    width: 270px;
}

/* .main__menu {position: fixed;  height: 100%;   margin: 0;  padding: 0;} */
.main__menu li {
    text-align: center;
}

.main__menu li a {
    display: inline-block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    position: relative;
}



@media (min-width: 961px) {
    .bottom_mobile_bar_nav {
        display: none;
    }
}

.bottom_mobile_bar {
    display: inline-block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    font-weight: bold;
}

.main__menu li a.uk-active {
    color: #db2121;
    font-weight: 600;
}

.main__menu ul li a.uk-active::after {
    content: "";
    position: absolute;
    background-color: #db2121;
    height: 1px;
    bottom: 7px;
    left: 0;
    right: 0;
}

.main__menu ul li a:hover::after {
    content: "";
    position: absolute;
    background-color: #db2121;
    height: 1px;
    bottom: 7px;
    left: 0;
    right: 0;
    transform: scaleX(1);
}

.main__menu ul li a::after {
    content: "";
    position: absolute;
    background-color: #db2121;
    height: 1px;
    bottom: 7px;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: transform 0.25s ease-out;
}

.main__menu li a:hover:not(.active) {
    color: #db2121;
}

#bcv-ft .bcv-grp-partners ul {
    justify-content: center !important;
}

.bcv-grp-partners ul li {
    padding: 10px;
    border: 1px solid #ced4da;
    margin-right: 10px;
}

.bcv-grp-partners img {
    height: 40px;
}

/* #bcv-wrp{padding: unset;} */
/* .bcv-row{padding: unset;} */
.bcv-grp-item {
    max-width: unset;
}

.bcv-grp-related {
    border: unset;
    padding: unset;
}

.bcv-menu-cate {
    border-bottom: unset;
}</style>