<template>
  <!-- <nav>
    <router-link to="https://baodaklak.vn/">Contest</router-link> |
    <router-link to="https://baodaklak.vn/about">About</router-link>
  </nav> -->


  <body>
    <div id="page_info" style="display: none;">
      <div id="site_id">fb9e3a03798789de0179a1704dea238e</div>
      <div id="parent_site_id">ff8080815e5b41fb015e5b817a510004</div>
      <div id="cate_id">fb9e3a03798789de0179d5286f994b43</div>
      <div id="parent_id">0</div>
      <div id="cate_url">/</div>
      <div id="article_title"></div>
      <div id="article_id"></div>
      <div id="cate_title">Trang chủ</div>
    </div>
    <div class="container">
      <div class="main-filter">
        <a href="https://baodaklak.vn/multimedia/" class="icon1">
          <span>Multimedia</span>
        </a>
        <a href="https://baodaklak.vn/doc-bao-in/" class="icon2">
          <span>Đọc Báo in</span>
        </a>

      </div>
      <div class="td-container-wrap">
        <HeaderPage />
        <router-view />
        <FooterPage />
      </div>
    </div>
    <div
      style="left: -1000px; overflow: scroll; position: absolute; top: -1000px; border: none; box-sizing: content-box; height: 200px; margin: 0px; padding: 0px; width: 200px;">
      <div style="border: none; box-sizing: content-box; height: 200px; margin: 0px; padding: 0px; width: 200px;"></div>
    </div><img
      src="https://baodaklak.vn/sc_service/api/statistic/statcollector?scUnitMapId=fb9e3a03798789de0179a1704dea238e&amp;cateId=fb9e3a03798789de0179d5286f994b43&amp;objectId=&amp;type=1&amp;pageTitle=Trang chủ&amp;referer=https://baodaklak.vn/"
      width="0" height="0" style="height: 0;display: none;">
    <div id="app" class="zs"></div>

  </body>
</template>

<script>
import HeaderPage from "@/components/HeaderPage.vue";
import FooterPage from "@/components/FooterPage.vue";
import { ref } from 'vue';
const dynamicData = ref('Báo Đắk Lắk điện tử');
export default {
  components: {
    HeaderPage,
    FooterPage
  },
  mounted() {
        // Kích hoạt UIkit
        document.title = `${dynamicData.value}`;
    }
  // Other configurations go here
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}</style>
